<template>
  <div class="page-qaform bg-main">
    <div class="box-tab">
      <p class="" @click="$router.replace({name: 'qaForm', query: q})">新增质检反馈</p>
      <p class="sel" >质检反馈汇总</p>
    </div>
    
    <fragment v-if="dataList.length">
      <div v-for="list in dataList" class="sec box-list">
        <div v-if="list[0]" class="tit-type">
          <span>{{list[0].type}}</span>
        </div>
        <div v-for="(item, idx) in list" class="item">
          <div class="date">
            <strong>问题{{idx+1}}</strong>
            <span>反馈 {{item.created_time}}</span>
          </div>
          <div class="title">录音时间定位（小时/分/秒）</div>
          <div class="box-time">
            <span>{{getTime(item.timing)[0]}}</span>
            <strong>:</strong>
            <span>{{getTime(item.timing)[1]}}</span>
            <strong>:</strong>
            <span>{{getTime(item.timing)[2]}}</span>
          </div>
          <div class="box-qa">
            <div class="text">
              {{item.description}}
            </div>
            <div class="sign">
              <img src="@/assets/i_switch.png" />
              <p>错误转写成</p>
            </div>
            <div class="text">
              {{item.description1}}
            </div>
          </div>
        </div>
      </div>
    </fragment >
    <div v-else class="sec">
      <el-empty description="暂无记录"></el-empty>
    </div>
</div>    
</template>

<script>
import Vue from 'vue'
import { postForm } from '@/api'
import moment from 'moment'


export default {
  data(){
    return {
      id: '',
      dataList: [],
      q: null,
    }
  },
  components:{
  },
  methods: {
    loadData() {
      postForm('/h5/feedback/getList', {
        task_id: this.id,
      }).then(res => {
        if (res.status === 1) {
          this.dataList = (res.data || [])//.slice(0,1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getTime(time) {
      const timeF = moment.utc(time*1000).format('HH:mm:ss');
      return timeF.split(':');
    }
  },
  created() {
    const q = this.$router.currentRoute.query || {};
    this.q = q;
    this.id = q.id;
    this.loadData();
  }
  
}
</script>

<style lang="less">
.page-qaform {
  position: relative;
  padding-top: 1rem;

  .box-tab {
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    p {
      max-width: 48%;
      display: flex;
      align-items: center;
      flex: 1;
      background: #fff;
      color: #5C5C5C;
      border-radius: 0.5rem;
      height: 2.3rem;
      justify-content: center;
      font-size: 0.7rem;
      margin: 0;
      &.sel {
        color: #409EFF;
        border: 1px solid #409EFF;
      }
    }
  }

  .box-list {
    margin-top: 0.5rem;
    padding: 0.75rem;

    .tit-type {
      span {
        font-weight: 400;
        display: line-block;
        background: rgba(237,90,84,0.16);;
        border-radius: 2px;
        color: #ED5A54;
        padding: 0.35rem 0.5rem;
      }
    }
    .item {
      border-bottom: 1px solid #EFEFEF;
      padding: 1rem 0;

      &:last-child {
        border: 0;
      }
    }
    .date {
      font-size: 0.6rem;
      font-weight: 400;
      color: #969799;

      strong {
        font-weight: 400;
        display: inline-block;
        background: rgba(55,126,234,0.15);
        border-radius: 2px;
        color: #377EEA;
        padding: 0.3rem 0.5rem;
        margin-right: 0.5rem;
      }
    }

    .title {
      font-size: 0.7rem;
      margin-top: 0.6rem;
      margin-bottom: 0.5rem;
      span {
        color: #ED5A54;
      }
    }

    .box-qa {
      display: flex;
      align-items: flex-end;

      .text {
        margin-top: 0.6rem;
        background: #F2F4F5;
        padding: 0.5rem;
        border-radius: 0.5rem;
        height: 5rem;
        flex: 1;
        font-size: 0.6rem;
        line-height: 1.2;
        color: #666666;
        overflow: auto;
      }
      .sign {
        text-align: center;
        font-size: 0.5rem;
        padding: 1.5rem 0.2rem;
        color: #000;
        img {
          width: 0.8rem;
          height: 0.8rem;
          display: inline-block;
          margin-bottom: 0.2rem;
        }
      }
    }
    .box-time {
      display: flex;
      align-items: center;
      strong {
        padding: 0 1rem;
      }
      span {
        flex: 1;
        border-radius: 0.2rem;
        border: 1px solid rgba(200,201,204,0.36);
        text-align: center;
        line-height: 1.5rem;
      }
    }
  }

}

</style>
